import EthImg from "../assets/chains/eth.svg";
import PolygonImg from "../assets/chains/matic.svg";
import BNBImg from "../assets/chains/bnb.svg";
import CeloImg from "../assets/chains/celo.webp";
import KrestImg from "../assets/chains/krest.svg";

export enum CHAINS {
  ethereum = "eth",
  // polygon = "matic",
  // bsc = "bnb",
  // celo = "celo",
  krest = "krest",
}

export enum ERROR_MESSAGES {
  required = "Address is required",
  invalid = "Address is invalid",
}

export interface IChainModel {
  chain: CHAINS;
  img: string;
  currencyName: string;
  displayName: string;
}

export interface IWsResponse {
  client_id: string;
  chain: string;
  address: string;
  status: string;
  message: string;
  result: IResult | null;
}

export interface IResult {
  timestamp_start: number;
  timestamp_end: number;
  tx_fee: string;
  tx_count: number;
  gas_used: number;
  co2: string;
}

export interface IRequest {
  chain: string;
  addresses: string[];
  block_start: number;
  block_end: number;
}

export const SUPPORTED_CHAINS_INFO_MAP: { [key: string]: IChainModel } = {
  [CHAINS.ethereum]: {
    chain: CHAINS.ethereum,
    img: EthImg,
    currencyName: "ETH",
    displayName: "Ethereum Mainnet",
  },
  // [CHAINS.polygon]: {
  //   chain: CHAINS.polygon,
  //   img: PolygonImg,
  //   currencyName: "MATIC",
  //   displayName: "Polygon Mainnet",
  // },
  // [CHAINS.bsc]: {
  //   chain: CHAINS.bsc,
  //   img: BNBImg,
  //   currencyName: "BNB",
  //   displayName: "BNB Smart Chain",
  // },
  // [CHAINS.celo]: {
  //   chain: CHAINS.celo,
  //   img: CeloImg,
  //   currencyName: "CELO",
  //   displayName: "Celo Chain",
  // },
  [CHAINS.krest]: {
    chain: CHAINS.krest,
    img: KrestImg,
    currencyName: "PEAQ",
    displayName: "Krest Network",
  },
};
