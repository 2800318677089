import React, { ChangeEvent, FC, useState } from "react";
import { Button, ButtonProps, DialogProps, TextField, TextFieldProps } from "@mui/material";
import { InfoTooltip } from "./InfoTooltip";
import { BaseDialog } from "./BaseDialog";
import { useFormStore } from "store/useForm";
import { useDialogStore } from "store/useDialog";

interface IDialog {
  open: DialogProps["open"];
  onClose: DialogProps["onClose"];
  value: TextFieldProps["value"];
  onChange: TextFieldProps["onChange"];
  onSubmit: ButtonProps["onClick"];
  onReset: ButtonProps["onClick"];
}

export const BulkImportDialog = () => {
  const [multiline, setMultiline] = useState("");

  const { formValues, setFormValues } = useFormStore();
  const { isImportDialogOpen, setImportDialogOpen } = useDialogStore();

  const resetAccounts = () => {
    setFormValues({ ...formValues, ...{ addresses: [""] } });
    setMultiline("");
  };

  const handleMultiline = (e: ChangeEvent<HTMLInputElement>) => {
    setMultiline(e.target.value);
  };

  const submitMultiline = () => {
    const addresses = multiline.trim().split("\n");
    const rawText = multiline.replace(/^\s+|\s+$/gm, "");

    if (rawText.length) {
      setFormValues({
        ...formValues,
        ...{
          addresses: addresses.filter((address) => address.length > 0).map((address) => address.trim()),
        },
      });
      setImportDialogOpen(false);
    }
  };

  return (
    <BaseDialog
      open={isImportDialogOpen}
      onClose={() => setImportDialogOpen(false)}
      title="Bulk Import"
    >
      <div className="px-6 py-4 max-w-[720px]">
        <div className="flex items-center gap-2 mb-3">
          <label>Accounts</label>
          <InfoTooltip
            title="Add addresses line by line without any punctuation or spaces"
            placement="right"
          />
        </div>

        <TextField
          multiline
          rows={8}
          fullWidth
          value={multiline}
          onChange={handleMultiline}
          className="mb-6"
          placeholder="Add addresses line by line without any punctuation or spaces"
        />
        <Button
          className="mb-2"
          onClick={submitMultiline}
          variant="contained"
          color="primary"
          size="medium"
          fullWidth
        >
          Import Addresses
        </Button>
        <Button
          onClick={resetAccounts}
          variant="text"
          color="primary"
          size="medium"
          fullWidth
        >
          Clear All
        </Button>
      </div>
    </BaseDialog>
  );
};
