import { Select, SelectProps } from "@mui/material";
import { useField } from "formik";
import React, { FC } from "react";

export const BaseSelect: FC<SelectProps> = (props) => {
  if (!props.name) {
    throw new Error("BaseSelect: name is required");
  }
  // eslint-disable-next-line
  const [field, meta] = useField(props.name!);
  const config = {
    ...field,
    ...props,
  };
  if (meta && meta.touched && meta.error) {
    config.error = true;
  }
  return <Select {...config} />;
};
