import { TextField, TextFieldProps } from "@mui/material";
import { ERROR_MESSAGES } from "types";
import { useField } from "formik";
import React, { FC } from "react";

export const BaseTextInput: FC<TextFieldProps> = (props) => {
  if (!props.name) {
    throw new Error("BaseTextInput: name is required");
  }
  // eslint-disable-next-line
  const [field, meta] = useField(props.name!);
  const config = {
    ...field,
    ...props,
  };

  if (meta && meta.touched && meta.error) {
    config.error = true;
    // @ts-ignore
    if (meta.error.length > 2) {
      config.helperText = meta.error;
    }
  }
  return <TextField {...config} />;
};
