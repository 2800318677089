import React, { useEffect, useRef, useState } from "react";
import { ERROR_MESSAGES, IWsResponse, IRequest, IResult, SUPPORTED_CHAINS_INFO_MAP, CHAINS } from "types";
import { Formik, Form, FieldArray, useFormikContext } from "formik";
import { InfoTooltip } from "./InfoTooltip";
import { BaseSelect } from "./BaseSelect";
import { Alert, Button, CircularProgress, Icon, MenuItem } from "@mui/material";
import { BaseTextInput } from "./BaseTextInput";
import * as Yup from "yup";
import { isAddress as isEtherAddress } from "ethers/lib/utils";
import { isAddress as isPolkadotAddress } from "@polkadot/util-crypto";
import { addressShortner } from "shared/utils/addressShortner";
import { useFormStore } from "store/useForm";

const wsUrl = process.env.REACT_APP_WS_ENDPOINT as string;
const baseUrl = process.env.REACT_APP_API_ENDPOINT as string;

let $chain: CHAINS;
let socket: WebSocket;

const int = Object.freeze({ co2: 0, gas_used: 0, tx_count: 0, tx_fee: 0 });

const ChainIdToken = () => {
  const {
    values: { chain },
  } = useFormikContext<any>();

  useEffect(() => {
    $chain = chain;
  }, [chain]);

  return null;
};

export const CalculatorForm = () => {
  const {
    formValues,
    data: storeData,
    setFormValues,
    setData,
    setError,
    isSubmitting,
    setIsSubmitting,
  } = useFormStore();

  const processedAddrs = useRef<{
    [key: string]: {
      co2: number;
      gas_used: number;
      tx_count: number;
      tx_fee: number;
    };
  }>({});

  const validate = Yup.object({
    addresses: Yup.array()
      .of(
        Yup.string()
          .required(ERROR_MESSAGES.required)
          .test("isAddress", ERROR_MESSAGES.invalid, (val) => {
            if (typeof val !== "string") return false;

            // More: https://wiki.polkadot.network/docs/learn-account-advanced
            return $chain === CHAINS.krest
              ? val.startsWith("5") // Substrate address starts with 5
                ? isPolkadotAddress(val)
                : isEtherAddress(val)
              : isEtherAddress(val);
          })
      )
      .min(1, "At least 1 address is required") // ! This test is for checking the uniqueness
      .test("duplicatedKeys", (val, that) => {
        if (!val) {
          return true;
        }
        const dups: string[] = [];
        const findDuplicates = (arr: (string | undefined)[] | undefined) =>
          arr && arr.filter((item, index) => arr.indexOf(item) !== index && item?.length);
        const duplicates = findDuplicates(val.map((item) => item?.toLowerCase()));
        const duplicatedVals = [...new Set(duplicates)];
        if (duplicatedVals.length)
          dups.push(
            `Duplicated addresses: ${duplicatedVals
              .map((val) => (typeof val === "string" && val.length >= 10 ? addressShortner(val) : val))
              .join(", ")}`
          );
        if (dups.length) return that.createError({ message: dups.join("\n") });
        else return true;
      }),
  });

  useEffect(() => {
    connectToWebSocket();
  }, []);

  function connectToWebSocket() {
    if (!socket || (socket && (socket.readyState === socket.CLOSED || socket.readyState === socket.CLOSING))) {
      socket = new WebSocket(wsUrl);

      socket.onopen = () => {
        console.log("WebSocket connected");
      };

      socket.onmessage = (event: MessageEvent): void => {
        const { address, result }: IWsResponse = JSON.parse(event.data);

        if (!processedAddrs.current[address]) {
          processedAddrs.current[address] = { ...int };
        }

        const val = processedAddrs.current[address];

        if (typeof result?.co2 === "string") {
          val.co2 += Number(result?.co2) ?? 0;
        }
        if (typeof result?.tx_fee === "string") {
          val.tx_fee += Number(result?.tx_fee) ?? 0;
        }
        if (typeof result?.gas_used === "number") {
          val.gas_used += result?.gas_used ?? 0;
        }
        if (typeof result?.tx_count === "number") {
          val.tx_count += result?.tx_count ?? 0;
        }
      };

      socket.onclose = () => {
        console.log("WebSocket closed");
        setIsSubmitting(false);

        if (Object.keys(processedAddrs.current).length === useFormStore.getState().formValues.addresses.length) {
          const data = Object.values(processedAddrs.current).reduce((acc, curr) => {
            return {
              co2: acc.co2 + curr.co2,
              gas_used: acc.gas_used + curr.gas_used,
              tx_count: acc.tx_count + curr.tx_count,
              tx_fee: acc.tx_fee + curr.tx_fee,
            };
          });

          console.log("data", data);
          setData(data);
        }

        // Reconnect after a short delay
        setTimeout(() => {
          console.log("WebSocket reconnecting...");
          connectToWebSocket();
        }, 500);
      };

      socket.onerror = (err) => {
        console.error("WebSocket connection error: ", err);
        // setError(err.message);
      };
    }
  }

  async function handleSubmit(payload: IRequest) {
    setFormValues(payload);
    setData(null);
    setError(null);
    processedAddrs.current = {};
    setIsSubmitting(true);

    // console.log(payload);

    if (socket.readyState === socket.OPEN) {
      socket.send(JSON.stringify(payload));
    }
  }

  return (
    <>
      <Formik
        initialValues={{ ...formValues }}
        onSubmit={handleSubmit}
        validationSchema={validate}
        validateOnMount={true}
        enableReinitialize={true}
      >
        {(formik) => (
          <>
            <ChainIdToken />
            <Form className="w-full">
              <div className="mb-6">
                <div className="flex items-center gap-2 mb-3">
                  <label
                    htmlFor="chain-select"
                    className="inline-block text-sm font-medium"
                  >
                    Select Chain
                  </label>
                  <InfoTooltip
                    title="Select chain"
                    placement="right"
                  />
                </div>
                <BaseSelect
                  id="chain-select"
                  className="w-full"
                  name="chain"
                >
                  {Object.keys(SUPPORTED_CHAINS_INFO_MAP).map((chain) => {
                    const { img, displayName } = SUPPORTED_CHAINS_INFO_MAP[chain];
                    return (
                      <MenuItem
                        value={chain}
                        key={chain}
                      >
                        <div className="flex items-center justify-center flex-1">
                          {/* IMG is hidden by css for the select menu */}
                          <img
                            src={img}
                            alt=""
                            className="mr-3 network-icon w-[24px] h-[24px]"
                          />
                          <span>{displayName}</span>
                        </div>
                      </MenuItem>
                    );
                  })}
                </BaseSelect>
              </div>

              <div className="mb-6">
                <div className="flex items-center gap-2 mb-3">
                  <label className="inline-block text-sm font-medium">Add Accounts</label>
                  <InfoTooltip
                    title="Add Accounts"
                    placement="right"
                  />
                </div>
                <FieldArray name="addresses">
                  {(fieldArrayProps) => {
                    const { push, remove, form } = fieldArrayProps;
                    const { values, errors } = form;
                    const { addresses } = values;
                    const { addresses: addressesErrors } = errors;

                    return (
                      <>
                        {addresses.map((item: string, index: number) => {
                          return (
                            <div
                              key={index}
                              className="flex gap-2 mb-4 last:mb-0"
                            >
                              <div className="flex-1">
                                <BaseTextInput
                                  name={`addresses[${index}]`}
                                  placeholder="EOA or Contract Address"
                                  fullWidth
                                />
                              </div>
                              <div className="h-56px">
                                <Button
                                  className="border-corner"
                                  sx={{
                                    width: "32px",
                                    minWidth: "32px",
                                    height: "100%",
                                    maxHeight: "100%",
                                    border: 1,
                                    borderRight: 0,
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                  }}
                                  variant="text"
                                  color="inherit"
                                  onClick={() => push("")}
                                  id={`addresses[${index}].add-button`}
                                >
                                  <Icon>add</Icon>
                                </Button>
                                <Button
                                  className="border-corner"
                                  sx={{
                                    width: "32px",
                                    minWidth: "32px",
                                    height: "100%",
                                    maxHeight: "100%",
                                    border: 1,
                                    borderLeft: 0,
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                  }}
                                  variant="text"
                                  color="inherit"
                                  onClick={() => remove(index)}
                                  disabled={addresses.length < 2}
                                  id={`addresses[${index}].remove-button`}
                                >
                                  <Icon>remove</Icon>
                                </Button>
                              </div>
                            </div>
                          );
                        })}
                        {errors && typeof addressesErrors === "string" && (
                          <Alert severity="error">{addressesErrors}</Alert>
                        )}
                      </>
                    );
                  }}
                </FieldArray>
              </div>

              <div className="mb-3">
                <Button
                  onClick={formik.submitForm}
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  sx={{
                    height: "64px",
                  }}
                >
                  {isSubmitting ? (
                    <CircularProgress
                      color="secondary"
                      size={20}
                    />
                  ) : (
                    "Query Emissions"
                  )}
                </Button>
              </div>
              {isSubmitting && (
                <Alert
                  severity="info"
                  className="text-center"
                  action={
                    <Button
                      disabled
                      color="inherit"
                      size="small"
                      onClick={() => {
                        socket.close();
                      }}
                    >
                      Cancel
                    </Button>
                  }
                >
                  This may take some time
                </Alert>
              )}
            </Form>
          </>
        )}
      </Formik>
      {/* {isSubmitting && (
        <div className="mt-4">
          Processed {processedAddrs.current.length} over {formValues.addresses.length}
        </div>
      )} */}
    </>
  );
};
