import React, { FC } from "react";
import { Dialog, DialogProps, DialogTitle } from "@mui/material";

export const BaseDialog: FC<DialogProps> = ({ children, title, ...props }) => {
  return (
    <Dialog {...props}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {children}
    </Dialog>
  );
};
