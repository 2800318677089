import React, { FC } from "react";
import { ButtonBase, ButtonBaseProps } from "@mui/material";
import { classNames } from "utils/styling";

export const BaseIconButton: FC<ButtonBaseProps> = ({ children, className, ...props }) => {
  const classes = {
    default: "w-8 h-8 rounded-lg bg-input text-white",
  };

  return (
    <ButtonBase
      className={classNames(classes["default"], className)}
      color="inherit"
      focusRipple={true}
      {...props}
    >
      {children}
    </ButtonBase>
  );
};
