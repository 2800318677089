import createTheme, { ThemeOptions } from "@mui/material/styles/createTheme";

export const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(", "),
  },
  palette: {
    mode: "dark",
    primary: {
      light: "#E3FDF8",
      main: "#90F9E4",
      dark: "#42F5D1",
      contrastText: "#000",
    },
    secondary: {
      light: "#091A2B",
      main: "#93AFD8",
      dark: "#4776BC",
      contrastText: "#fff",
    },
    text: {
      primary: "#fff",
      secondary: "rgba(255,255,255,0.69)",
      disabled: "rgba(255,255,255,0.5)",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        text: {
          borderColor: "rgba(255,255,255,0.69)",
        },
      },
    },
  },
};
export const theme = createTheme(themeOptions);
