import React from "react";
import { Button, Icon } from "@mui/material";
import CreateImg from "../assets/createPool.png";

export const AppBanner = ({ className }: { className?: string }) => {
  return (
    <div
      className={`${className} overflow-hidden relative flex justify-between py-6 rounded-lg bg-card-gradient bg-no-repeat bg-cover px-7`}
    >
      <div className="lg:w-[calc(100%-240px)]">
        <h2 className="mb-1 text-xl font-medium uppercase font-title">
          Making Web3 The First
          <br />
          <strong>
            <span className="text-primary-main">Climate Positive</span> Industry
          </strong>
        </h2>

        <p className="mb-4 text-xs font-extralight">
          Menthol Protocol automatically tracks your on-chain emissions and energy consumption. Become Cooler. Simply
          Menthol.
        </p>

        <Button
          endIcon={<Icon>arrow_forward</Icon>}
          color="primary"
          variant="contained"
          href="https://mentholprotocol.com"
          target="_blank"
          size="small"
        >
          Visit The Website
        </Button>
      </div>

      <div className="absolute bottom-0 right-[36px] hidden lg:flex lg:w-[240px]">
        <img
          src={CreateImg}
          alt=""
        />
      </div>
    </div>
  );
};
