// https://github.com/pmndrs/zustand/blob/main/docs/guides/typescript.md
import create from "zustand";

interface IFormState {
  isImportDialogOpen: boolean;
  isSettingsDialogOpen: boolean;
  setImportDialogOpen: (payload: boolean) => void;
  setSettingsDialogOpen: (payload: boolean) => void;
}

export const useDialogStore = create<IFormState>((set) => ({
  isImportDialogOpen: false,
  isSettingsDialogOpen: false,
  setImportDialogOpen: (payload: boolean) => set({ isImportDialogOpen: payload }),
  setSettingsDialogOpen: (payload: boolean) => set({ isSettingsDialogOpen: payload }),
}));
