export function convertCO2Units(co2: number): string {
  const units = ["g", "kg", "t"];
  let co2g = co2; // convert to grams
  let unitIndex = 0;

  while (co2g >= 1000 && unitIndex < units.length - 1) {
    co2g /= 1000;
    unitIndex++;
  }

  return `${co2g.toFixed(2)} ${units[unitIndex]}`;
}
