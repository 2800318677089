import React, { useEffect, useState } from "react";
import { Alert, Button } from "@mui/material";
import { ReactSvgInjector } from "react-svg-injector";
import { AppBanner } from "components/AppBanner";
import { BaseIconButton } from "components/BaseIconButton";
import { CalculatorForm } from "components/CalculatorForm";
import { BulkImportDialog } from "components/BulkImportDialog";
import { ExpertSettingsDialog } from "components/ExpertSettingsDialog";
import { useFormStore } from "store/useForm";
import { useDialogStore } from "store/useDialog";
import GearIcon from "./assets/icons/gear.svg";
import TtIcon from "./assets/icons/tt.svg";
import { Helmet } from "react-helmet-async";
import { convertCO2Units } from "utils/conversion";

interface IResData {
  co2: number;
  gas_used: number;
  tx_fee: number;
  tx_count: number;
}

const initialData: IResData = Object.freeze({ co2: 0, gas_used: 0, tx_fee: 0, tx_count: 0 });
const cardClasses = ["h-full px-7 py-5 rounded-lg shadow-lg bg-card"];

function App() {
  const { formValues, data: formData, error: formError, setError } = useFormStore();
  const { setSettingsDialogOpen, setImportDialogOpen } = useDialogStore();

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_DAPP_TITLE || "Carbon Calculator"} | Menthol Protocol</title>
      </Helmet>

      <div className="flex items-center justify-center min-h-screen py-12">
        <div className="container max-w-720px">
          <AppBanner className="mb-8" />

          <div className="space-y-4">
            <div>
              <div className={`${cardClasses.join(" ")}`}>
                <div className="relative mb-7">
                  <h1 className="m-0 text-sm font-medium leading-8 text-left sm:text-xl sm:text-center">
                    {process.env.REACT_APP_DAPP_TITLE || "Carbon Calculator"}
                  </h1>

                  <div className="absolute top-0 right-0 space-x-2">
                    <BaseIconButton
                      onClick={() => setSettingsDialogOpen(true)}
                      id="setting"
                    >
                      <ReactSvgInjector
                        src={GearIcon}
                        className="h-4"
                      ></ReactSvgInjector>
                    </BaseIconButton>
                    <BaseIconButton
                      onClick={() => setImportDialogOpen(true)}
                      id="import"
                    >
                      <ReactSvgInjector
                        src={TtIcon}
                        className="h-4"
                      ></ReactSvgInjector>
                    </BaseIconButton>
                  </div>
                </div>

                <div className="flex flex-col items-center justify-center px-5">
                  <CalculatorForm />
                  <a
                    className="inline-block mt-6 text-sm font-light text-center text-white underline"
                    href="https://docs.mentholprotocol.com/introduction/master"
                    target="_blank"
                    rel="noreferrer"
                  >
                    How is it calculated?
                  </a>
                </div>
                {formError && (
                  <Alert
                    severity="error"
                    className="mt-4"
                    onClose={() => setError(undefined)}
                  >
                    {formError}
                  </Alert>
                )}
              </div>
            </div>

            <div
              className={`${cardClasses.join(" ")} px-12 py-8`}
              data-cy="co2"
            >
              <div className="grid w-full grid-cols-3">
                <div className="col-span-2">
                  <h2 className="mb-3 text-sm font-normal text-gray-300">
                    CO<sub>2</sub> Emitted
                  </h2>
                  <p className="text-3xl font-bold lg:text-5xl">
                    {typeof formData?.co2 === "number"
                      ? `${convertCO2Units(formData.co2)}`
                      : formData?.co2 === null
                      ? "0.00 g"
                      : "-"}
                  </p>
                </div>
                <div className="col-span-1">
                  <Button
                    variant="contained"
                    size="large"
                    href={`${process.env.REACT_APP_DAPP_URL}/coolpool/add?addresses=${formValues.addresses.join(",")}`}
                    target="_blank"
                    sx={{
                      height: "100%",
                      width: "100%",
                      textAlign: "center",
                    }}
                    disabled={true}
                  >
                    Offset Now
                  </Button>
                </div>
              </div>
            </div>

            <div className="flex items-stretch justify-between gap-4">
              <div
                className={`w-full ${cardClasses.join(" ")}`}
                data-cy="txs"
              >
                <div className="flex flex-col items-center justify-center h-full text-center">
                  <h2 className="text-sm font-normal text-gray-300">No. Of Txs</h2>
                  <p className="text-lg font-semibold leading-8">
                    {typeof formData?.tx_count === "number"
                      ? Intl.NumberFormat("en-US").format(Number(formData?.tx_count))
                      : "-"}
                  </p>
                </div>
              </div>

              <div
                className={`w-full ${cardClasses.join(" ")}`}
                data-cy="gas"
              >
                <div className="flex flex-col items-center justify-center h-full text-center">
                  <h2 className="text-sm font-normal text-gray-300">Gas Used</h2>
                  <p className="text-lg font-semibold leading-8">
                    {typeof formData?.gas_used === "number"
                      ? Intl.NumberFormat("en-US").format(Number(formData?.gas_used))
                      : "-"}
                  </p>
                </div>
              </div>

              {/* <div className="col-span-3 lg:col-span-1">
                <div
                  className={`${cardClasses.join(" ")}`}
                  data-cy="block_range"
                >
                  <div className="flex flex-col items-center justify-center h-full text-center">
                    <h2 className="text-sm font-normal text-gray-300">Tx Fee</h2>
                    <p className="text-lg font-semibold leading-8">
                      {typeof formData?.tx_fee === "number"
                        ? Intl.NumberFormat("en-US").format(Number(formData?.tx_fee))
                        : "-"}
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
            {/* <div className="col-span-3 text-center">
              <details className="mx-auto w-fit">
                <summary>More Details</summary>
                {formData && (
                  <pre className="p-4 mt-2 text-sm text-left rounded-lg bg-black/10">
                    <code>{JSON.stringify(formData, null, 2)}</code>
                  </pre>
                )}
              </details>
            </div> */}
          </div>
        </div>
      </div>

      <BulkImportDialog />

      <ExpertSettingsDialog />
    </>
  );
}

export default App;
