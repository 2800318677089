import React, { FC } from "react";
import { Icon, Tooltip, Zoom } from "@mui/material";

export const InfoTooltip: FC<any> = ({ className, popperClasses, ...props }) => {
  return (
    <Tooltip
      {...props}
      className={"text-white/[0.56] " + className}
      classes={{ popper: popperClasses }}
      TransitionComponent={Zoom}
    >
      <Icon style={{ fontSize: 16 }}>info_outlined</Icon>
    </Tooltip>
  );
};
