import React, { FC, useState, ChangeEvent } from "react";
import { Button, DialogProps } from "@mui/material";
import { InfoTooltip } from "./InfoTooltip";
import { useFormStore } from "store/useForm";
import { useDialogStore } from "store/useDialog";
import { BaseDialog } from "./BaseDialog";
import { BaseTextInput } from "./BaseTextInput";
import { Formik, Form } from "formik";
import * as Yup from "yup";

export const ExpertSettingsDialog = () => {
  const { isSettingsDialogOpen, setSettingsDialogOpen } = useDialogStore();
  const { formValues, setStartingBlock } = useFormStore();

  const onSubmit = (values: any) => {
    setStartingBlock(Number(values.startingBlock));
    setSettingsDialogOpen(false);
  };

  const validate = Yup.object({
    startingBlock: Yup.number()
      .min(0)
      .required("Starting block is required")
      .typeError("Starting block must be a number"),
  });

  return (
    <BaseDialog
      open={isSettingsDialogOpen}
      onClose={() => setSettingsDialogOpen(false)}
      title="Expert Settings"
    >
      <div className="px-6 py-4 max-w-[720px]">
        <Formik
          initialValues={{
            startingBlock: formValues.block_start?.[0],
          }}
          validationSchema={validate}
          onSubmit={onSubmit}
          validateOnMount={true}
        >
          {(formik) => (
            <Form>
              <div className="mb-6">
                <div className="flex items-center gap-2 mb-3">
                  <label>Starting Block</label>
                  <InfoTooltip
                    title="Add Accounts"
                    placement="right"
                  />
                </div>
                <BaseTextInput
                  name="startingBlock"
                  fullWidth
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                />
              </div>
              <Button
                disabled={!formik.isValid}
                className="mb-3"
                onClick={formik.submitForm}
                variant="contained"
                color="primary"
                size="medium"
                fullWidth
                id="submit"
              >
                Save
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </BaseDialog>
  );
};
