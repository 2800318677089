// https://github.com/pmndrs/zustand/blob/main/docs/guides/typescript.md
import create from "zustand";
import produce from "immer";
import { CHAINS, IWsResponse, IRequest, IResult } from "types";

type Data = {
  co2: number;
  gas_used: number;
  tx_count: number;
  tx_fee: number;
} | null;

export interface IFormState {
  formValues: IRequest;
  data: Data;
  error: any;
  isSubmitting: boolean;
  setStartingBlock: (payload: number) => void;
  setFormValues: (payload: IRequest) => void;
  setData: (payload: Data | null) => void;
  setError: (payload: any) => void;
  setIsSubmitting: (payload: boolean) => void;
}

const initialValues: IRequest = Object.freeze({
  chain: CHAINS.ethereum,
  addresses: [""],
  block_start: 0,
  block_end: 999_999_999,
});

export const useFormStore = create<IFormState>((set) => ({
  formValues: { ...initialValues },
  data: null,
  error: null,
  isSubmitting: false,
  setStartingBlock: (payload: number) =>
    set(
      produce((state) => {
        state.formValues.block_start = payload;
      })
    ),
  setFormValues: (payload: IFormState["formValues"]) => set({ formValues: payload }),
  setData: (payload: IFormState["data"]) => {
    set({ data: payload });
  },
  setError: (payload: IFormState["error"]) => set({ error: payload }),
  setIsSubmitting: (payload: IFormState["isSubmitting"]) => set({ isSubmitting: payload }),
}));
